<template lang="pug">
b-modal(id="are-you-there-virtualrowFSFB" v-model="showAreYouThere" hide-footer hide-header size="sm" centered no-close-on-backdrop).AreYouThere
  .d-flex.justify-content-end.pb-1.w-full(style="min-height: 62px;")
    .d-flex.justify-content-center.align-items-center.containerTimerC.w-full
      div(v-if="showCCT")
        circular-count-down-timer(
          main-circle-id="circle-3"
          :show-value="false"
          @update="onUpdateTimer"
          :circles="circles")
      img(src="../../../assets/houseVirtualrowFSFBWhite.svg" alt="houseVirtualrowFSFBWhite" @click="onGoHome").houseVirtualrowFSFB
  p.fonterCurrentUser.textHero.mb-5.mx-auto.d-block
    strong ¿Aún estás ahí?
  b-button.buttonService(@click="onCloseModal") Sí, continuar
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AreYouThere",

  data() {
    return {
      showAreYouThere: false,
      intervalClick: null,
      showCCT: true,
      circles: [
        {
          id: "circle-3",
          size: 60,
          // stepLength: 16.25,
          // steps: 16,
          stepLength: 0.25,
          steps: 8.25,
          classList: "circle-3",
          fillColor: "#ffffff",
          strokeColor: "#001698",
          strokeWidth: 3.5
        }
      ]
    };
  },

  props: {
    seconds: {
      default: 15000
    },
    showAreYouThereProp: {
      type: Boolean
    }
  },

  watch: {
    showAreYouThereProp(val) {
      if (this.intervalClick) clearTimeout(this.intervalClick);
      if (val) {
        this.intervalClick = setInterval(() => {
          document.querySelector(".ContainerModal").click();
        }, 10);

        const t = setTimeout(() => {
          const el = document.querySelector("#are-you-there-virtualrowFSFB");
          if (el) {
            el.addEventListener("click", this.onClickEl);
          }
          clearTimeout(t);
        }, 10);
      }

      this.showAreYouThere = val;
    }
  },

  methods: {
    ...mapActions({
      goToView: "virtualrowFSFB/goToView"
    }),

    onGoHome() {
      if (this.intervalClick) clearTimeout(this.intervalClick);
      this.goToView({ view: "Home" });
    },

    onCloseModal() {
      if (this.intervalClick) clearTimeout(this.intervalClick);
      this.$emit("onShowAreYouThereClose");
      this.showAreYouThere = false;
    },

    onClickEl() {
      this.showCCT = false;
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        this.showCCT = true;
      }, 8);
    },

    onUpdateTimer(event) {
      if (event["circle-3"] === 8) {
        this.onGoHome();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.buttonService {
  font-family: "Gilroy-Semibold";
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: #009dac;
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
  transition: 1s background-color;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
}

.fonterCurrentUser {
  max-width: 476px;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #788591;
}
</style>
