<template lang="pug">
.Payment
  h3.fonterH3.w-100.mt-5.pt-3.mx-auto.d-block.text-center !Hola!
  p.textHero.SelectText.m-0.mx-auto.d-block.mt-4 Selecciona la forma en que deseas pagar tu cita
  .cards.d-flex.align-item-center.mx-auto.mt-5(:class=`!isPaymentDatafono && !urlExist && !isPaymentAgentEnable ? '' :
  isOneElement ? 'justify-content-center' :
  areTwoElement ? 'justify-content-between isPaymentDatafono' :
  'justify-content-between'`)
    .cardPayment(v-if="urlExist")
      p.text-card Paga en línea escaneando este código QR
      .position-relative(v-show="!qrLoading")
        img(src="@/assets/atrilFSFB/border-green.png" alt="border-green").border-green.mx-auto.d-block.mt-5.pt-2
        #qrCode.qrCode
      .position-relative(v-show="qrLoading")
        Loading.border-green.mx-auto.d-block.mt-5.pt-2
    .cardPayment(v-if="isPaymentDatafono")
      p.text-card-others Realiza tu pago por medio de nuestro módulo de datafono
      img(src="@/assets/atrilFSFB/payment_datafono.png" alt="payment_datafono").payment_datafono
      button(@click="openTerminalPay").text-center.buttonService
        span
          | Continuar
    .cardPayment(v-if="isPaymentAgentEnable")
      p.text-card-others Realiza tu pago con uno de nuestros asesores
      img(src="@/assets/atrilFSFB/user.png" alt="user").payment_datafono
      button(@click="setAppointment").text-center.buttonService
        span
          | Continuar

  //- Modals
  b-modal(id="pays-modal-virtualrowFSFB" hide-footer hide-header size="xl" centered no-close-on-backdrop)
    .ContainerModal
      .d-flex.justify-content-end.pr-3.w-full(style="min-height: 62px;")
        .d-flex.justify-content-center.align-items-center.containerTimerC.w-full(v-if="!cancelPay")
          div(v-if="showCCT")
            circular-count-down-timer(
              main-circle-id="circle-2"
              :show-value="false"
              @update="onUpdateTimer"
              :circles="!loading ? circles : circlesLoading")
          img(src="../../assets/houseVirtualrowFSFBWhiteGray.svg" alt="houseVirtualrowFSFBWhiteGray").houseVirtualrowFSFB
        AreYouThere(:showAreYouThereProp="showAreYouThere" @onShowAreYouThereClose="onShowAreYouThereClose")
      //- div(v-else)
      //-   .d-flex.justify-content-end
      //-     ProgressModal2
      //-   AreYouThere(:stopMainTimer="stopTimerPayments" :seconds="60000")
      .d-block.text-center.mx-auto
        p.mt-4.mb-0.fonterCurrentUser {{ cancelPay ? 'Cancelando pago' : failPay ? 'Realizando el pago' : successPay ? 'Para la cita de CONSULTA PRIMERA' : 'Realizarás el pago' }} de {{ toCurrency(currentAppointment.cost_appoinment) }} a través del datáfono
        p.my-3.fonterCurrentUser(v-if="!loading") Para la cita de {{ currentAppointment.attention.detail }}
        p(v-show="failPay").mb-3.text-danger.fonterCurrentUser La transacción ha sido declinada o cancelada
        p(v-show="successPay").mb-3.text-success.fonterCurrentUser El pago de {{ toCurrency(currentAppointment.cost_appoinment) }} se realizo con éxito
        p(v-show="cancelPay").mb-3.text-danger.fonterCurrentUser El pago se ha cancelado.
        p.pb-5.fonterCurrentUser(v-show="!failPay && !successPay && !cancelPay && !inPayment") ¿Qué deseas hacer?
        //- p.pb-5.fonterCurrentUser(v-show="cancelPay") Cerrando...
        //- p.pb-5.fonterCurrentUser(v-show="successPay") Redireccionando...
        p.pb-2.fonterCurrentUser(v-show="successPay")
          
        p.pb-5.fonterCurrentUser(v-show="failPay")
          button( id="download" style="padding: 0;border: none;background: none;color: #3fc9c3" @click="printInvice").buttonServiceModal.ml-3 Descargar Comprobante
          button(@click="onCloseModal" style="margin-left: 14%;margin-top: 20px;").buttonServiceModal
            span Continuar
        p.pb-5.fonterCurrentUser(v-show="!failPay && pending") Tarjeta leída, esperando respuesta del datáfono...
        p.pb-2.pt-5.fonterCurrentUser(v-show="inPayment && (!failPay && !successPay && !cancelPay)") Realizando el pago...
          Loading.pb-5.border-green.mx-auto.d-block.mt-5.pt-2
        //- div(v-show="inPayment && (!failPay && !successPay && !cancelPay)")
        //- .position-relative(v-if="loading || failPay")
        //-   ProgressCounter(@endTimer="cancelPayAction" ref="ProgressCounter")
        p.pt-5.pb-5.fonterCurrentUser(v-show="inPayment && (!failPay && !successPay && !cancelPay)")  Por favor, <b>presiona la tecla verde del datáfono y sigue las instrucciones</b>.
        p.label-danger.mt-3.p-3(v-show="inPayment && !successPay") Si deseas salir debes cancelar la transacción desde el datáfono, oprimiendo la tecla roja
      .d-block.containerBox
        div(v-if="!loading && !failPay")
          button(v-show="!failPay && !successPay && !cancelPay" @click="makePay" style="font-size: 23px;" ).buttonServiceModal.mb-3
            span Pagar con Datáfono
        button(v-show="!failPay && !successPay && !cancelPay" v-if="!loading" @click="closeModal").buttonServiceModal.canceledbtn
          span Cancelar
        .container-confirm-btns(v-if="showConfirmCancel" v-show="!failPay && !successPay && !cancelPay")
          p.mb-0.fonterCurrentUser ¡En caso de que esté realizando una transacción en este momento en el datáfono espere a que esta finalice!
          p.my-3.fonterCurrentUser ¿Está seguro de cancelar el pago?
          .d-flex.container-btns
            button(@click="closeModal").buttonServiceModal.canceledbtn.smallButton: span Si
            button(@click="() => toggleShowConfirmCancel(false)").buttonServiceModal.smallButton: span No
</template>

<script>
import { mapActions, mapState } from "vuex";
import AlertMdi from "mdi-vue/Alert.vue";

import QRCode from "easyqrcodejs";
import ProgressCounter from "./components/ProgressCounter.vue";
import Loading from "../components/Spinner.vue";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import AreYouThere from "./components/AreYouThere.vue";
import ProgressModal from "./components/ProgressModal.vue";
import ProgressModal2 from "./components/ProgressModal2.vue";

export default {
  name: "Payment",

  components: {
    ProgressCounter,
    Loading,
    AreYouThere,
    ProgressModal,
    ProgressModal2,
    AlertMdi
  },

  props: ["stopTimerPayments", "onStopTimer"],

  data() {
    return {
      isPaymentDatafono: false,
      isPaymentAgentEnable: false,
      interval: null,
      intervalFetch: null,
      interPays: null,
      failPay: false,
      intervalClick: null,
      cancelPay: false,
      showAreYouThere: false,
      successPay: false,
      pending: false,
      loading: false,
      respFromPays: {},
      inPayment: false,
      idTransaction: null,
      terminal: null,
      showCCT: true,
      showConfirmCancel: false,
      respTransationFundation: null,
      circles: [
        {
          id: "circle-2",
          size: 60,
          stepLength: 0.25,
          steps: 8.25,
          classList: "circle-2",
          fillColor: "#ffffff",
          strokeColor: "#001698",
          strokeWidth: 3.5
        }
      ],
      circlesLoading: [
        {
          id: "circle-2",
          size: 60,
          stepLength: 0.25,
          steps: 100.25,
          classList: "circle-2",
          fillColor: "#ffffff",
          strokeColor: "#001698",
          strokeWidth: 3.5
        }
      ],

      // this can be use to reset css counter
      showProgressBeforePay: true,
      showProgressAfterPay: true,

      // URL to place pay must to exist
      urlExist: false
    };
  },

  computed: {
    ...mapState({
      currentUser: state => state.virtualrowFSFB.currentUser,
      currentAppointment: state => state.virtualrowFSFB.currentAppointment,
      currentSite: state => state.virtualrowFSFB.currentSite,
      qrLoading: state => state.virtualrowFSFB.qrLoading,
      sedeCode: state => state.virtualrowFSFB.sedeCode,
      sites: state => state.virtualrowFSFB.sites,
      customArea: state => state.virtualrowFSFB.customArea,
      isAtril: state => state.virtualrowFSFB.isAtril,
      view: state => state.virtualrowFSFB.view,
      respPayments: state => state.virtualrowFSFB.respPayments,
      env: state => state.env,
      dataPayment: state => state.virtualrowFSFB.dataPayment
    }),

    letterCapPos() {
      return Number(this.env.VUE_APP_LETTERCAP_POS) || 40;
    },

    isOneElement() {
      return this.isPaymentDatafono && !this.urlExist && !this.isPaymentAgentEnable || 
        !this.isPaymentDatafono && this.urlExist && !this.isPaymentAgentEnable ||
        !this.isPaymentDatafono && !this.urlExist && this.isPaymentAgentEnable
    },

    areTwoElement() {
      return this.isPaymentDatafono && this.urlExist && !this.isPaymentAgentEnable || 
        this.isPaymentDatafono && !this.urlExist && this.isPaymentAgentEnable ||
        !this.isPaymentDatafono && this.urlExist && this.isPaymentAgentEnable
    }
  },

  async mounted() {
    let uuid = uuidv4();

    // change timer from component
    // init from payments in 26 seconds (25 in payment view)
    this.$emit("restartTimerPayents", 26);

    if(this.currentAppointment.appoinment_url_paid){

      this.urlExist = true;

      // send data for pay
      let info = await this.sendMessageForPay({
        company_id: this.currentSite.branch_id,
        user_document: this.currentUser.doc,
        id_cita_pago: this.currentAppointment.number_form,
        uuid: uuid,
        area: this.$route.query.area,
        docType: this.currentUser.tdoc.code,
        sedeCode: this.sedeCode
      });
      
      // Pays with QR
      info = Object.keys(info.data).length > 0 ? info.data : {};
  
      new QRCode(document.getElementById("qrCode"), {
        text: `${location.origin}/#/mobile_fsfb/${this.$route.params.code}?area=${this.$route.query.area}&id_cita_pago=${info.id_cita_pago}&row_id=${info.id}`
      });
      
      let message;
      this.intervalFetch = setInterval(async () => {
        message = await this.checkMessageStatus({
          id_cita_pago: this.currentAppointment.number_form
        });
        if (message.response) {
          if (message.data.length > 0) {
            message = message.data.find(d => d.uuid == uuid);
            if (message.status == "Leído") {
              if (this.intervalFetch) {
                clearInterval(this.intervalFetch);
                this.intervalFetch = null;
              }
              this.$store.commit("virtualrowFSFB/setState", {
                key: "stopTimerCircle",
                value: false
              });
              this.goToView({ view: "Home" });
              return;
            }
          }
        }
      }, 4000);
    } else this.urlExist = false;


    // this.interval = setInterval(() => {
    //   // this.$store.dispatch("setTimer");
    // }, 1000);

    // show datafono icon
    let queues = this.sites.find(s => s.code == this.sedeCode);
    let customAreas = queues.customAreas[this.customArea];
    this.isPaymentDatafono = customAreas.showButtonPaymentDataphone;

    // show agent payments button
    this.isPaymentAgentEnable = !customAreas.hiddenButtonPaymentAgent;

    // terminal name (payments logs)
    this.terminal = customAreas.terminal;
  },

  destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.intervalFetch) {
      clearInterval(this.intervalFetch);
    }

    if (this.interPays) {
      clearInterval(this.interPays);
    }

    if (this.intervalClick) clearTimeout(this.intervalClick);

    const t = setTimeout(() => {
      const el = document.querySelector(".ContainerModal");
      if (el) {
        el.removeEventListener("click", this.onClickEl);
      }
      clearTimeout(t);
    }, 10);

    // restart timer to 15 seconds
    this.$emit("restartTimerPayents", 15);

    // clear data
    this.clear();
  },

  watch: {
    async respPayments(r) {
      console.log(r);
      let resp_s = r.split(",")[1];
      let code = r.split(",")[0];
      console.log(`code: ${code}`)

      // Sucess
      if (resp_s == "Msj:00") {
        this.successPay = true;
        this.failPay = false;
        this.cancelPay = false;

        // Stop timer
        this.$emit("stopTimerPayments");

        // Stop internal timer for cancel pays
        if(this.$refs.ProgressCounter) this.$refs.ProgressCounter.stopProgress();

        // Sync pays
        this.respTransationFundation = await this.syncPaymentsFSFB({
          payment_date: moment().format("YYYY-MM-DD"),
          pac_num: String(this.currentAppointment.pac_num),
          form_number: this.currentAppointment.number_form,
          cups: this.currentAppointment.attention.code,
          transaction_identifier: String(this.terminal),
          transaction_amount: String(this.currentAppointment.cost_appoinment),
          pay_patient: "S",
          tdoc: this.currentAppointment.tdoc.code,
          doc: this.currentAppointment.doc,
          name: this.currentUser.names,
          last_name: this.currentUser.last_name,
          mother_last_name:
            this.currentUser.mother_last_name || this.currentUser.last_name,
          email: this.currentUser.email,
          phone: this.currentUser.phone
        });

        // Print Error
        if (!this.respTransationFundation) {
          this.printInvice(false);
        }

        setTimeout(() => {
          this.clearMessagePays();
          this.respFromPays = null;
          this.setAppointment();
        }, 5000)
      }

      // Fail
      if (resp_s == "Msj:XX" || code == 'Cod:99') {
        this.failPay = true;
        this.cancelPay = false;
        this.successPay = false;

        // Stop check Payments
        if (this.interPays) clearInterval(this.interPays);

        // cancel transaction
        this.cancelPayment(this.respFromPays);

        // Close modal only
        setTimeout(() => {
          this.clearMessagePays();
          this.clear();
          this.successPay = false;
          this.failPay = false;
          this.cancelPay = false;
          this.respFromPays = null;
          this.pending = false;
          this.inPayment = false;
          this.$emit("startTimer");
          this.onCloseModal();
        }, 5000);
      }

      // pending
      if (resp_s == "Msj:") {
        this.pending = true;
      }

      if (resp_s == "Msj:00" || resp_s == "Msj:XX") {
        this.respTransationFundation = this.respTransationFundation
          ? typeof this.respTransationFundation == "object"
            ? this.respTransationFundation[0]?.result_tran
            : "N/R"
          : "N/R";

        this.loading = false;
        this.saveLogsPayments({
          appointment: this.currentAppointment.number_form,
          attention: this.currentAppointment.attention.detail,
          transaction: this.respTransationFundation,
          terminal: this.terminal,
          atril_area: this.customArea,
          documento: this.currentUser.doc,
          response: r,
          action: "Respuesta de la terminal",
          sync_data: JSON.stringify({
            payment_date: moment().format("YYYY-MM-DD"),
            pac_num: String(this.currentAppointment.pac_num),
            form_number: this.currentAppointment.number_form,
            cups: this.currentAppointment.attention.code,
            transaction_identifier: String(this.terminal),
            transaction_amount: String(this.currentAppointment.cost_appoinment),
            pay_patient: "S",
            tdoc: this.currentAppointment.tdoc.code,
            doc: this.currentAppointment.doc,
            name: this.currentUser.names,
            last_name: this.currentUser.last_name,
            mother_last_name:
              this.currentUser.mother_last_name || this.currentUser.last_name,
            email: this.currentUser.email,
            phone: this.currentUser.phone
          }),
          data: this.dataPayment
        });
        // Stop internal timer for cancel pays
        if(this.$refs.ProgressCounter) this.$refs.ProgressCounter.stopProgress();
      }

      // Check Token
      if(code == 'Cod:04'){
        this.refreshTokenPays();
      }
    }
  },

  methods: {
    ...mapActions({
      sendMessageForPay: "virtualrowFSFB/sendMessageForPay",
      checkMessageStatus: "virtualrowFSFB/checkMessageStatus",
      // removeTimer: "removeTimer",
      // setTimer: "setTimer",
      goToView: "virtualrowFSFB/goToView",
      sendCrediPay: "virtualrowFSFB/sendCrediPay",
      startTransactionT: "virtualrowFSFB/startTransaction",
      checkCrediPay: "virtualrowFSFB/checkCrediPay",
      cancelPayment: "virtualrowFSFB/cancelPayment",
      setCitaCM: "virtualrowFSFB/setCitaCM",
      clearMessagePays: "virtualrowFSFB/clearMessagePays",
      syncPaymentsFSFB: "virtualrowFSFB/syncPaymentsFSFB",
      saveLogsPayments: "virtualrowFSFB/saveLogsPayments",
      clear: "virtualrowFSFB/clear",
      refreshTokenPays: "virtualrowFSFB/refreshTokenPays",
    }),

    toggleShowConfirmCancel(val) {
      this.showConfirmCancel = val;
    },

    onCloseModal() {
      const t = setTimeout(() => {
        const el = document.querySelector(".ContainerModal");
        if (el) {
          el.removeEventListener("click", this.onClickEl);
        }
        clearTimeout(t);
      }, 10);
      this.$bvModal.hide("pays-modal-virtualrowFSFB");
    },

    async openTerminalPay() {
      // if (this.isAtril) await this.setTimer();
      this.$store.commit("virtualrowFSFB/setState", {
        key: "stopTimerCircle",
        value: true
      });

      this.$bvModal.show("pays-modal-virtualrowFSFB");
      // this.$emit("restartTimerPayents");
      if (this.intervalClick) clearTimeout(this.intervalClick);
      this.intervalClick = setInterval(() => {
        document.body.click();
      }, 10);
      const t = setTimeout(() => {
        const el = document.querySelector(".ContainerModal");
        if (el) {
          el.addEventListener("click", this.onClickEl);
        }
        clearTimeout(t);
      }, 10);
      this.onStopTimer();
    },

    onClickEl() {
      this.showCCT = false;
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        this.showCCT = true;
      }, 8);
    },

    // Pay button from modal
    async makePay() {
      this.loading = true;
      this.inPayment = true;
      // this.$store.dispatch("setGoTime", true);
      const sync_data =  JSON.stringify({
          payment_date: moment().format("YYYY-MM-DD"),
          pac_num: String(this.currentAppointment.pac_num),
          form_number: this.currentAppointment.number_form,
          cups: this.currentAppointment.attention.code,
          transaction_identifier: String(this.terminal),
          transaction_amount: String(this.currentAppointment.cost_appoinment),
          pay_patient: "S",
          tdoc: this.currentAppointment.tdoc.code,
          doc: this.currentAppointment.doc,
          name: this.currentUser.names,
          last_name: this.currentUser.last_name,
          mother_last_name:
            this.currentUser.mother_last_name || this.currentUser.last_name,
          email: this.currentUser.email,
          phone: this.currentUser.phone
      })
      // start Transaction
      console.log("INITIAL SYNC DATA", sync_data)
      const transaction = await this.startTransactionT(sync_data);
      const transaction_id = transaction.data.transaction_id;

      // wait one second
      setTimeout(async () => {
        // this.$emit("stopTimerPayments");
        // this.idTransaction = Math.floor(Math.random() * 9999999999);
        this.respFromPays = await this.sendCrediPay(transaction_id);

        // Chek pay status
        clearInterval(this.interPays);
        this.interPays = setInterval(() => {
          this.checkCrediPay(this.respFromPays);
        }, 3000);
      }, 1000);
    },

    async setAppointment() {
      // if (this.isAtril) await this.removeTimer();
      await this.setCitaCM({
        ...this.currentAppointment,
        appoinment_url_paid: null,
        cost_appoinment: 0,
        appoinment_paid: this.respTransationFundation ? "SI" : "NO"
      });
      // if (this.isAtril) await this.setTimer();
    },

    closeModal() {
      if (this.inPayment) {
        this.cancelPayAction();
        // Redirect to QR when fail
        setTimeout(() => {
          this.clearMessagePays();
          this.respFromPays = null;
          this.$store.commit("virtualrowFSFB/setState", {
            key: "stopTimerCircle",
            value: false
          });
          this.$bvModal.hide("pays-modal-virtualrowFSFB");
          this.goToView({ view: "QR" });
        }, 5000);
      } else {
        // this.$emit("startTimer");
        this.$store.commit("virtualrowFSFB/setState", {
          key: "stopTimerCircle",
          value: true
        });
        this.$bvModal.hide("pays-modal-virtualrowFSFB");
        if (this.intervalClick) clearTimeout(this.intervalClick);
      }
    },

    cancelPayAction() {
      this.cancelPayment(this.respFromPays);
      this.cancelPay = true;

      // stop check payment status
      clearInterval(this.interPays);

      this.saveLogsPayments({
        appointment: this.currentAppointment.number_form,
        attention: this.currentAppointment.attention.detail,
        transaction: "N/A",
        terminal: this.terminal,
        atril_area: this.customArea,
        documento: this.currentUser.doc,
        response: this.respPayments,
        action: "Cancelación de transacción",
        sync_data: JSON.stringify({
          payment_date: moment().format("YYYY-MM-DD"),
          pac_num: String(this.currentAppointment.pac_num),
          form_number: this.currentAppointment.number_form,
          cups: this.currentAppointment.attention.code,
          transaction_identifier: String(this.terminal),
          transaction_amount: String(this.currentAppointment.cost_appoinment),
          pay_patient: "S",
          tdoc: this.currentAppointment.tdoc.code,
          doc: this.currentAppointment.doc,
          name: this.currentUser.names,
          last_name: this.currentUser.last_name,
          mother_last_name:
            this.currentUser.mother_last_name || this.currentUser.last_name,
          email: this.currentUser.email,
          phone: this.currentUser.phone
        }),
        data: this.dataPayment
      });
    },

    continueAppointment() {
      this.clearMessagePays();
      this.respFromPays = null;
      this.setAppointment();
    },

    async printInvice(error = false) {
      // if (this.isAtril) await this.$store.dispatch("setTimer", true);
      let message = !this.failPay ? "Pago Exitoso" : "Pago Fallido";
      let turn = `${message}. ${
        !error
          ? "Rererencia: " + this.respTransationFundation
          : "Id cita: " + this.currentAppointment.number_form
      }`;
      let staff = `Dr(a). ${this.currentAppointment.staff.name} ${this.currentAppointment.staff.last_name}`;
      let date_appoinment = `Hora Actual: ${this.currentAppointment.date_appoinment} ${this.currentAppointment.hour_appoinment}`;
      let cost_appoinment;
      if (error)
        cost_appoinment = this.toCurrency(
          this.currentAppointment.cost_appoinment
        );
      else
        cost_appoinment =
          "Tenemos problemas con sistema de pagos, por favor lleva este comprobante impreso a la línea de frente";
      let speciality = this.currentAppointment.attention.detail;
      let posPrinterInfo = [
        { text: "" },
        {
          text: this.normalizer(turn),
          size: {
            width: 1,
            height: 1
          },
          style: "bu",
          align: "ct"
        },
        { text: "" },
        {
          text: this.normalizer(staff),
          size: {
            width: 0,
            height: 1
          },
          style: "n"
        },
        { text: "" },
        {
          text: this.normalizer(speciality),
          size: {
            width: 0,
            height: 0
          }
        },
        { text: this.normalizer(cost_appoinment) },
        { text: "" },
        { text: date_appoinment },
        { text: "" },
        { text: "" },
        { text: "" }
      ];
      let url = `${
        location.origin
      }/#/print_invoice?turn=${encodeURIComponent(turn)}&tramite=${encodeURIComponent(
        staff
      )}&branch=${encodeURIComponent(speciality)}&queue=${encodeURIComponent(
        cost_appoinment
      )}`;
      console.log("To print", url);
      if (typeof window.ElectronPrinter === "function") {
        try {
          await window.ElectronPrinter({
            mode: "printURL",
            url,
            // opts: {
            //   height: 400,
            //   width: 350
            // },
            optsPrinter: {
              silent: true,
              printBackground: false,
              color: false,
              margin: {
                marginType: "printableArea"
              },
              landscape: false
              // scaleFactor: 0.5
              // pageRanges: [{ from: 0, to: 0 }]
              // pageSize: {
              //   height: 500
              // },
              // pagesPerSheet: 1,
              // collate: false
            },
            posPrinter: {
              posPrinterInfo,
              posPrinterSetup: this.currentSite?.posPrinterSetup
            }
          });
        } catch (error) {
          console.error("Error when print ticket", error);
        }
      } else console.log("ElectronPrinter not exist");
    },

    normalizer(str) {
      return typeof str === "string"
        ? str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        : str;
    },

    onUpdateTimer(event) {
      if (!this.loading) {
        if (event["circle-2"] === 8) {
          this.showAreYouThere = true;
        }
      } else {
        if (event["circle-2"] === 100) {
          this.showAreYouThere = true;
        }
      }
    },

    onShowAreYouThereClose() {
      this.showAreYouThere = false;
    },

    // currency
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP"
      });
      return formatter.format(value).split(",")[0];
    },

    resetProgressBeforePay(){
      this.showProgressBeforePay = false;
      setTimeout(() => {
        this.showProgressBeforePay = true;
      }, 200);
      this.$store.dispatch("setGoTime", true);
    },

    resetProgressAfterPay(){
      this.showProgressAfterPay = false;
      setTimeout(() => {
        this.showProgressAfterPay = true;
      }, 200);
      this.$store.dispatch("setGoTime", true);
    }
  }
};
</script>

<style lang="scss" scoped>
.SelectText {
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  line-height: 45px;
  color: #283645;
}

.fonterH3 {
  color: #59616a;
}

.cardPayment {
  width: 584px;
  height: 616px;
  box-shadow: 0px 5.77255px 11.5451px rgba(21, 21, 21, 0.08);
  background: #ffffff;
  border-radius: 20px;
}

.cards {
  max-width: 1792px;
}

.text-card {
  margin-top: 40px;
}

.text-card,
.text-card-others {
  width: 432px;
  margin: auto;
  margin-top: 60px;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 31px;
  text-align: center;
  color: #788591;
}

.text-card-others {
  margin-top: 40px;
}

.qrCode {
  width: 189px;
  max-width: 189px;
  position: absolute;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%);
}

.payment_datafono {
  margin: auto;
  display: block;
  margin-top: 96px;
}

.buttonService,
.buttonServiceModal {
  font-family: "Gilroy-Light";
  width: 100%;
  max-width: 476px;
  margin: auto;
  margin-top: 50px;
  display: block;
  height: 89px;
  padding: 1.5rem 2rem;
  background-color: #009dac;
  border-radius: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  line-height: 45px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
  transition: 1s background-color;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
}

.buttonServiceModal {
  padding: 24px 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  max-width: 350px;
  margin: 0;
}

.containerBox {
  width: 100%;
  max-width: 100%;
  margin: auto;
  text-align: center;
}

.containerBox button {
  max-width: 100%;
}

.isPaymentDatafono {
  max-width: 1318px;
}

.canceledbtn {
  background-color: 	#e82424;
  color: #ffffff;
}

.smallButton {
  max-width: 50%;
  padding: 10px;
}

.container-btns {
  gap: 15px;
}

.fonterCurrentUser {
  max-width: 476px;
  margin: auto;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #25282b;
}

.label-danger {
  background-color: #e7c1ca;
  color: #942047;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  border-radius: 15px;
  max-width: fit-content;
  margin: 0 auto;
  display: block;
}

// .lds-dual-ring {
//   position: absolute;
//   left: 50%;
//   top: -12px;
//   transform: translate(-50%, -50%);
// }

// .lds-dual-ring::after {
//   width: 50px;
//   height: 50px;
//   border: 6px solid #ffffff;
//   border-color: #ffffff transparent #ffffff transparent;
// }
</style>
